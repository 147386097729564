.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline {
  height: 300px;
  width: 5px;
  background-color: #e5e5e5;
}

.stub1 {
  line-height: 300px;
  font-size: 24px;
  background-color: #eae4e4;
}

.stub2 {
  height: 1000px;
}

.circle {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: #e5e5e5;
}

.circleWrapper {
  position: relative;
}

.message {
  position: absolute;
  top: -30px;
  left: 65px;
  //min-width: 300px;
  font-weight: bold;
  //width: 562px;
  height: 211px;
  background: linear-gradient(105.42deg, rgba(255, 255, 255, 0.11) -3.07%, rgba(255, 255, 255, 0.11) 49.04%, rgba(255, 255, 255, 0) 97.84%);
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
  border-radius: 40px;
  padding: 0 20px;
  text-align: center;
}

.message2 {
  position: absolute;
  top: -30px;
  left: -601px;
  //min-width: 300px;
  font-weight: bold;
  //width: 562px;
  height: 211px;
  background: linear-gradient(105.42deg, rgba(255, 255, 255, 0.11) -3.07%, rgba(255, 255, 255, 0.11) 49.04%, rgba(255, 255, 255, 0) 97.84%);
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
  border-radius: 40px;
  padding: 0 20px;
  text-align: center;
}

@media (min-width: 1281px) {
  .message {
    min-width: 300px !important;
    width: 562px !important;
  }

  .message2 {
    min-width: 300px !important;
    width: 562px !important;
  }
}