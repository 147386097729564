.marketking-footer {
    padding-top: 10px;
    background: linear-gradient(180deg, #DEB570 0%, #CC822A 43.11%);
}

.footer-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.035em;
    text-transform: capitalize;
    color: #141111;
}

.footer-menu {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    color: #141111;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;

}

.footer-submenu {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-decoration: none !important;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
}

.footer-copyright {
    color: #141111;
    text-decoration: none !important;
}

.getmoreupdate-section {
    padding: 10px;
    margin-bottom: 50px;
    text-align: center;
    color: white;

    .container {
        h1 {
            font-weight: 600;
            font-size: 48px;
            line-height: 59px;
        }

        p {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            color: #FFD693;
        }
    }

    .subscribe {
        padding-top: 30px;
        padding-bottom: 100px;

        input {
            font-size: 18px !important;
            padding-left: 50px !important;
        }

        .form-control {
            height: 71px;
            backdrop-filter: blur(5.83659px);
            background-color: rgb(255, 255, 255);
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            font-size: 22px;
            border-radius: 116.732px;
            color: rgb(0, 0, 0);
        }

        .text-area {
            padding: 10px;
        }

        .search button {
            top: 4px;
            width: 150px;
            height: 63px;
            right: 0px;
            background: black;
            font-size: 17px;
        }
    }


}