.tabs-carousel {
    width: 87%;
    margin-top: 15px;
    border-radius: 0px;
    margin-left: 56px;
}

.carousel-control-next-icon {
    border-radius: 100% !important;
    background-image: url("../Images/Slider/arrow-right.svg") !important;
    filter: none !important;
}

.carousel-control-prev-icon {
    border-radius: 100% !important;
    background-image: url("../Images/Slider/arrow-left.svg") !important;
    filter: none !important;
}