body {
    background-color: #1D1B1B !important;
    font-family: 'Poppins', sans-serif !important;
    overflow-x: hidden !important;
}

h2 {
    font-weight: 700;
    font-size: 53px;
    line-height: 86px;
    letter-spacing: 0.035em;
    text-transform: capitalize;
    background: linear-gradient(90deg, #FFD693 -6.48%, #DDAD63 39.63%, #D78A2D 92.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

p {
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    letter-spacing: 0.035em;
    color: #FFFFFF;
}

.btn {
    height: 56.39px;
    background: linear-gradient(90deg, #D29449 31.64%, #FFD693 99.97%);
    border-radius: 60px !important;
    color: #fff !important;
    font-size: 19px !important;
    font-weight: 600 !important;
    transition: all 1s ease !important;
}

a.nav-link {
    color: #fff;
}

.navBarItems>li {
    padding: 10px;
}

div#navbarNav {
    justify-content: flex-end;
}

a.nav-link {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    text-transform: capitalize;
    color: #ADB9C7 !important;
    flex: none;
    order: 4;
    flex-grow: 0;
}

.headerBtn {
    height: 56px;
    background: linear-gradient(270deg, #D19348 31.65%, #DFB671 100%);
    border-radius: 16px !important;
    color: #fff !important;
    padding: 12px 16px !important;
}

.navbar-expand-lg .navbar-nav {
    align-items: center;
}

section.scroll1 {
    padding: 4vw 0;
}

.scroll1Text>h1 {
    font-weight: 700;
    font-size: 53px;
    line-height: 86px;
    letter-spacing: 0.035em;
    text-transform: capitalize;
    background: linear-gradient(90deg, #FFD693 -6.48%, #DDAD63 39.63%, #D78A2D 92.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: left !important;
}

.scroll1Text>p {
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.035em;
    text-transform: capitalize;
    color: #fff;
    text-align: left !important;
}

.scroll1Img>img {
    top: -80px;
    position: relative;
    left: -68px;
    width: 100%;
}

.scroll1Count>div>h5 {
    font-weight: 900;
    font-size: 36px;
    line-height: 54px;
    color: #D39952;
    text-align: left !important;
}

.scroll1Count>div>p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #D39952;
    font-family: inter;
    text-align: left !important;
}

.row.scroll1Btn {
    padding: 16px 0;
    text-align: left !important;
}

button.exploreBtn {
    height: 56.39px;
    background: linear-gradient(90deg, #D29449 31.64%, #FFD693 99.97%);
    width: 80%;
    border-radius: 60px;
    color: #fff;
    font-size: 19px;
    font-weight: 600;
}

.row.scroll1Count {
    /* margin: 20px 0; */
    padding: 10px 0;
}

button.contactBtn {
    background: #1D1B1B;
    border: 1px solid #D39952;
    height: 56.39px;
    width: 77%;
    color: #D39952;
    border-radius: 60px;
    font-size: 19px;
    font-weight: 600;
}

.contactBtn:hover {
    border: 1px solid #D39952;
}

.scroll2 {
    padding: 0 0 40px 0;
}

.iconBox {
    background: linear-gradient(105.42deg, rgba(255, 255, 255, 0.11) -3.07%, rgba(255, 255, 255, 0.11) 49.04%, rgba(255, 255, 255, 0) 97.84%);
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
    border-radius: 40px;
    display: flex;
    width: 145px;
    height: 145px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.iconBoxContainer {
    margin: '0 auto';
    cursor: pointer;
    text-align: center;
}

.socialIcons {
    padding: 40px 0;
}

.iconBoxText {
    font-weight: 600;
    font-size: 19px;
    line-height: 54px;
    text-transform: capitalize;
    color: #ECBF78;
}

.scroll3 {
    padding: 4vw 0;
}

.scroll3title {
    font-weight: 600;
    font-size: 43px;
    line-height: 64px;
}

p.scroll3-p {
    padding-top: 40px;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.035em;
    text-transform: capitalize;
    text-align: left;
}

.col-md-6.scroll3Img>img {
    width: 100%;
}

.scroll3Col2 {
    padding: 90px 0 0 0;
}

.scroll3Row {
    padding: 60px 0;
}

.scrollBtn {
    padding: 0 54px !important;
    margin-top: 50px;
}

.navigation-header {
    margin-top: 20px;
    gap: 140px;
}

.hometop-section {
    margin-top: 60px;
}

.scroll4 {
    padding: 4vw 0 120px 0;
}

.timelineTitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 86px;
    letter-spacing: 0.035em;
    text-transform: capitalize;
    background: linear-gradient(90deg, #FFD693 -6.48%, #DDAD63 39.63%, #D78A2D 92.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.timelineText {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.scroll5 {
    padding: 120px 0;
}

.items_filter {
    background: linear-gradient(105.42deg, rgba(255, 255, 255, 0.11) -3.07%, rgba(255, 255, 255, 0.11) 49.04%, rgba(255, 255, 255, 0) 97.84%);
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
    border-radius: 18px;
    padding: 20px;
}

ul.de_nav.text-left>li {
    display: inline;
    padding: 0 20px;
    font-weight: 600;
    font-size: 23px;
    line-height: 86px;
    color: #fff;
    cursor: pointer;
}

.li-active {
    text-transform: capitalize;
    background: linear-gradient(90deg, #FFD693 -6.48%, #DDAD63 39.63%, #D78A2D 92.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.title {
    font-weight: 700;
    font-size: 47px;
    line-height: 86px;
    letter-spacing: 0.035em;
    text-transform: capitalize;
    background: linear-gradient(90deg, #FFD693 -6.48%, #DDAD63 39.63%, #D78A2D 92.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.accordionItem {
    text-align: center;
}

h2.accordionHeader {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 9.55%, rgba(255, 255, 255, 0.09) 100%) !important;
    border-radius: 16.0176px !important;
    text-align: center !important;
}

.accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 9.55%, rgba(255, 255, 255, 0.09) 100%) !important;
    border-radius: 16.0176px;
}

.accordion-button {
    text-align: center !important;
    display: block !important;
}

h3.accordionTitle {
    font-weight: 700;
    font-size: 35.474px;
    line-height: 45px;
    background: linear-gradient(90deg, #FFD693 0%, #DDA150 98.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

button.accordion-button.collapsed {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 9.55%, rgba(255, 255, 255, 0.09) 100%) !important;
    border-radius: 16.0176px !important;
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: none !important;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 9.55%, rgba(255, 255, 255, 0.09) 100%) !important;
    border-radius: 16.0176px;
}



.headermenu {
    padding: 20px !important;
    background: #1D1B1B;
}

.container-fluid {
    padding-right: 10% !important;
    padding-left: 10% !important;
}

.only-mobile {
    display: none;
}

.only-desktop {
    display: block;
}

.headermenu {
    padding: 10px !important;
    background: transparent !important;
}

.sticky-top {
    background: #1D1B1B !important;
}

.modal-layout {
    padding: 40px;
    margin-top: 100px;
}

.modal-content {
    text-align: center;
    background: #161515 !important;
    border: 1px solid #D39952 !important;
    color: white !important;
}

.modal-header {
    color: #D39952 !important;
    font-weight: 900;
}

.btn-close {
    background-image: url("https://www.freeiconspng.com/thumbs/close-icon/close-icon-29.png") !important;
    border: 2px solid #D39952 !important;
    border-radius: 50% !important;
}

.modal-body {
    font-weight: 400;
    font-size: 17px;
}

.modal-body-content {
    padding: 10px;
}


.card-body {
    text-align: center !important;
    height: 430px !important;


    .h5 {
        font-size: 2.25rem !important;
        color: #D39952 !important;
    }
}

.card-text {
    color: white !important;
    text-align: center !important;
}

.card {
    background: #1D1B1B !important;
    border-radius: 40px !important;
    border: 1px solid #D39952 !important;
}