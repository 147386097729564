@media only screen and (max-width: 600px) {
    .body {
        h3 {
            font-size: 30px !important;
            font-size: 16px !important;
            line-height: 41px !important;
        }
    }

    .navbar-brand {
        img {
            width: 70%;
        }
    }

    .navbar-toggler {
        border: 1px solid #ffffff !important;
    }

    .navbar-toggler-icon {
        background-image: url("../Images/Icon/nav-toggle.png") !important;
    }

    .hometop-section {
        margin-top: 1px;
    }

    .scroll1Text>h1 {
        font-size: 41px !important;
        line-height: 46px !important;
        //text-align: center !important;
    }

    .scroll1Text>p {
        font-size: 20px !important;
        // text-align: center !important;
    }

    .scroll1Count>div>h5 {
        // text-align: center !important;
    }

    .scroll1Count>div>p {
        // text-align: center !important;
    }

    .scroll1Img {
        display: none;
    }

    .row .scroll1Btn {
        text-align: left;
        padding: 16px 10px;
    }

    .p-0 {
        margin-bottom: 10px;
    }

    .text-center {
        font-size: 20px !important;
        line-height: 30px !important;
    }

    .text-center {
        font-size: 16px !important;
    }

    .scroll3title {
        font-size: 26px !important;
        line-height: 47px !important;
        //text-align: center !important;
    }

    p.scroll3-p {
        // text-align: center;
        font-size: 15px;
        line-height: 30px;
    }

    .scrollBtn {
        margin-left: 26% !important;
    }

    .message {
        height: auto !important;
        left: 38px !important;
    }

    .message2 {
        height: auto !important;
        left: -169px !important;
        right: 27px !important;
    }

    .timelineTitle {
        font-size: 15px;
        line-height: 25px;
    }



    .iconBox {
        width: 85%;
    }

    .iconBoxText {
        font-size: 16px;
    }

    .tabs-carousel {
        width: 112% !important;
        margin-left: -36px;
        margin-top: 15px !important;
    }

    ul.de_nav.text-left>li {
        display: inline-table !important;
        font-size: 20px;
        line-height: 53px;
    }

    .text-left {
        margin-left: -33px;
    }

    .offcanvas {
        background: linear-gradient(180deg, #DEB570 0%, #CC822A 43.11%) !important;
    }

    .offcanvas-body {
        // background: linear-gradient(180deg, #DEB570 0%, #CC822A 43.11%) !important;

        a {
            color: black !important;
        }
    }

    .faq {
        font-size: 20px !important;
    }

    .accordion {
        h3 {
            font-size: 19px !important;
        }

        p {
            font-size: 12px !important;
            line-height: 32px !important;
        }
    }

    .only-mobile {
        display: block;
    }

    .only-desktop {
        display: none;
    }

    .container-fluid {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .exploreBtn {
        width: 90% !important;
    }

    .contactBtn {
        width: 90% !important;
    }



    .scroll6 {
        padding: 90px 0 !important;
    }

    .px-5 {
        padding: 0 !important;
    }

    .scroll2 {
        padding: 40px 0;
    }

    button.exploreBtn {
        height: 46.39px;
    }

    button.contactBtn {
        height: 46.39px;
    }

    .scroll4 {
        display: none;
    }

    .scroll5 {
        padding: 0px 0 !important;
        margin-bottom: 50px;
    }

    .casestudy {
        .card-body .h5 {
            font-size: 1.30rem !important;
        }

        .card-text {
            font-size: 14px !important;
        }
    }

    .socialIcons {
        padding: 0 !important;
    }
}